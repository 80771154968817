import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Copyright from "../components/Copyright";
import AppBar from "../components/actionBar/AppBar";
import { GraphContext } from "../context/GraphContext";
import { WebSocketContext } from "../context/WebSocketContext";
import { DashboardContext } from "../context/DashboardContext";
import { FileHandlingContext } from "../context/FileHandlingContext";
import { GeneralContext } from "../context/GeneralContext";
import LeftSide from "../components/leftSide/LeftSide";
import RightSide from "../components/rightSide/RightSide";
import GraphContainer from "../components/middle/GraphContainer";
import "./dashboard.scss";
import {
  // getListOfTermsToUpdate,
  performChi2Message,
  updatedChi2,
} from "../components/rightSide/fit/fitLogic";
import { ReadyState } from "react-use-websocket";
import { AuthContext } from "../context/AuthContext";
import {
  cleanModelsFromUnusedCurves,
  generateWarningObject,
  getRangesForModelsFromGraphs,
  hasProperty,
} from "../utils/helpers";
import StickyFileWindow from "../components/leftSide/Files/StickyFileWindow";
import { isClickedOutside } from "../components/leftSide/Files/FileUploadLogic";
import BroadcastModal from "../components/commonComponents/BroadcastModal";
import Modal from "react-modal";
import SingleFileReprocess from "../components/leftSide/Files/fileProcessing/SingleFileReprocess";
import {
  deepCopy,
  getModelById,
  replaceModelById,
} from "../components/leftSide/Models/modelLogic";
import WindowSelector from "../components/windowSelector/WindowSelector";
// import { getUpdatedUniqueIds } from "../components/rightSide/parameters/parameterLogic";

//this is the main function of the whole application
export default function Dashboard() {
  const navigate = useNavigate();
  const { selectedFiles, setSelectedFiles, updateSelected } =
    useContext(FileHandlingContext);
  const { chi2Terms, setChi2Terms, graphs, selectedChi2Terms } =
    useContext(GraphContext);
  const {
    lastJsonMessage,
    readyState,
    sendMessage,
    authSent,
    setAuthSent,
    isFitOngoing,
    setIsFitOngoing,
    retrieveRequestData,
    deleteRequestDataEntry,
    sendJsonMessage,
  } = useContext(WebSocketContext);
  const {
    setWarnings,
    setNewWarningCount,
    cleanupNeeded,
    setCleanupNeeded,
    modelData,
    setModelData,
    // showLeftPanel,
    // setShowLeftPanel,
    fileCallFromGraph,
    setFileCallFromGraph,
    fileToChange,
    setFileToChange,
    uploadedFiles,
    setUploadedFiles,
    setTotalActiveParams,
    chi2ReqAllowed,
    setChi2ReqAllowed,
    chi2QueRef,
    // requestedVdf,
    // setRequestedVdf,
  } = useContext(DashboardContext);
  const { authToken, isAuthReady, currentUser } = useContext(AuthContext);
  const {
    setBroadcastMessage,
    limitedToast,
    limitedWarningToast,
    recordedErrorLog,
  } = useContext(GeneralContext);
  const [broadcastOpen, setBroadcastOpen] = useState(false);
  const [reprocessOpen, setReprocessOpen] = useState(false);
  const [fileToReprocess, setFileToReprocess] = useState(null);

  // We need state and ref in this case, because ref updates are not caught in StickyFileWindow immediately
  // and state change is not visible in useEffect where we have click outside logic
  const [fromGraphState, setfromGraphState] = useState(false);
  const fromGraph = useRef(false);
  const dashboardRef = useRef();

  useEffect(() => {
    try {
      if (lastJsonMessage) {
        if (hasProperty(lastJsonMessage, "Broadcast")) {
          setBroadcastMessage(lastJsonMessage.Broadcast);
          setBroadcastOpen(true);
        }
      }
    } catch (error) {
      recordedErrorLog(
        "Last Json message broadcast useEffect has failed: ",
        error
      );
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    try {
      if (fileToChange !== null) {
        const foundFile = uploadedFiles.find(
          (uFile) => uFile.ID === fileToChange
        );
        if (foundFile !== undefined) {
          setFileToReprocess(foundFile);
          setReprocessOpen(true);
        }
      }
    } catch (error) {
      recordedErrorLog("'fileToChange' useEffect has failed: ", error);
    }
  }, [fileToChange]);

  useEffect(() => {
    fromGraph.current = false;
    setfromGraphState(false);
  }, [selectedFiles]);

  useEffect(() => {
    if (fileCallFromGraph && !fromGraph.current) {
      fromGraph.current = true;
      setfromGraphState(true);
    }
  }, [fileCallFromGraph]);

  useEffect(() => {
    if (fileCallFromGraph) {
      setFileCallFromGraph(false);
    }
  }, [fileCallFromGraph]);

  function resetSelection() {
    setSelectedFiles([]);
    fromGraph.current = false;
    setfromGraphState(false);
  }

  useEffect(() => {
    function handleMouseDownOutside(event) {
      if (
        !fromGraph.current &&
        dashboardRef.current &&
        isClickedOutside(event)
      ) {
        setSelectedFiles([]);
      }
    }

    document.addEventListener("mousedown", handleMouseDownOutside);

    return () => {
      document.removeEventListener("mousedown", handleMouseDownOutside);
    };
  }, [dashboardRef]);

  useEffect(() => {
    if (isAuthReady) {
      if (authToken === "") {
        navigate("/");
      }
    }
  }, [isAuthReady]);

  useEffect(() => {
    if (ReadyState.OPEN === readyState && !authSent) {
      sendMessage("Token: " + authToken);
      setAuthSent(true);
    }
  }, [readyState]);

  useEffect(() => {
    if (chi2ReqAllowed && chi2QueRef.current.length > 0) {
      performChi2Message(
        [],
        modelData,
        uploadedFiles,
        sendJsonMessage,
        currentUser,
        recordedErrorLog,
        chi2ReqAllowed,
        setChi2ReqAllowed,
        chi2QueRef
      );
    }
  }, [chi2ReqAllowed]);

  // We are cleaning up unused curves from models here
  useEffect(() => {
    try {
      if (cleanupNeeded) {
        const neededRangeDictionary = getRangesForModelsFromGraphs(graphs);

        const cleanedModels = cleanModelsFromUnusedCurves(
          modelData,
          neededRangeDictionary
        );

        setModelData(cleanedModels);

        setCleanupNeeded(false);
      }
    } catch (error) {
      recordedErrorLog("Cleanup needed useEffect failure: ", error);
    }
  }, [cleanupNeeded]);

  useEffect(() => {
    try {
      if (
        lastJsonMessage != null &&
        hasProperty(lastJsonMessage, "Error") &&
        lastJsonMessage.Error.length > 0
      ) {
        for (let i = 0; i < lastJsonMessage.Error.length; i++) {
          const error = lastJsonMessage.Error[i];
          const idRequest = hasProperty(lastJsonMessage, "requestID")
            ? lastJsonMessage.requestID
            : "none";
          limitedToast(error);
          generateWarningObject(
            error,
            2,
            setWarnings,
            setNewWarningCount,
            true,
            idRequest
          );
        }
        if (isFitOngoing) {
          setIsFitOngoing(false);
        }
      }
      if (
        lastJsonMessage != null &&
        hasProperty(lastJsonMessage, "Warning") &&
        lastJsonMessage.Warning.length > 0
      ) {
        for (let i = 0; i < lastJsonMessage.Warning.length; i++) {
          const warning = lastJsonMessage.Warning[i];
          limitedWarningToast(warning);
          generateWarningObject(warning, 1, setWarnings, setNewWarningCount);
        }
      }
    } catch (error) {
      recordedErrorLog("Error and Warning handling useEffect failure: ", error);
    }
  }, [lastJsonMessage]);

  function handleChi2Message(jsonMessage) {
    const newTerms = jsonMessage.Fit.chi2.chi2s;
    const newTotalAp = jsonMessage.Fit.chi2.nActiveParameters;

    setTotalActiveParams(newTotalAp);

    const updatedTerms = updatedChi2(newTerms, chi2Terms);

    setChi2Terms(updatedTerms);
  }

  function handleVdfResponse(lastMessage, requestedVdfs) {
    try {
      let modelsCopy = deepCopy(modelData);
      for (let i = 0; i < requestedVdfs.length; i++) {
        const requestedModelVDF = requestedVdfs[i];
        const foundVdf = lastMessage.Model.SendModel.find(
          (model) => model.modelid === requestedModelVDF.id
        );

        if (foundVdf !== null) {
          const modelToUpdate = deepCopy(
            getModelById(requestedModelVDF.id, modelsCopy)
          );

          modelToUpdate.vdfData = {
            ...modelToUpdate.vdfData,
            ...foundVdf.vdf,
          };
          if (hasProperty(requestedModelVDF, "gridData")) {
            modelToUpdate.vdfData.gridData = requestedModelVDF.gridData;
          }
          if (hasProperty(requestedModelVDF, "gridlinear")) {
            modelToUpdate.vdfData.gridlinear = requestedModelVDF.gridlinear;
          }

          modelsCopy = replaceModelById(
            modelsCopy,
            modelToUpdate,
            requestedModelVDF.id
          );
        } else {
          console.warn(
            "VDF requested but not received for: ",
            requestedModelVDF[i]
          );
        }
      }

      setModelData(modelsCopy);

      // const allUpdatedIds = requestedVdfs.map((update) => update.id);
      // const termsToUpdate = getListOfTermsToUpdate(
      //   chi2Terms,
      //   getUpdatedUniqueIds(allUpdatedIds, modelsCopy)
      // );
      // if (termsToUpdate.length > 0) {
      if (chi2Terms.length > 0 && selectedChi2Terms.length > 0) {
        const termsToSend = chi2Terms.filter((term) => {
          if (
            selectedChi2Terms.some((id) => id === term.id) &&
            term.weight > 0
          ) {
            return true;
          } else {
            return false;
          }
        });

        if (termsToSend.length > 0) {
          performChi2Message(
            termsToSend,
            modelsCopy,
            uploadedFiles,
            sendJsonMessage,
            currentUser,
            recordedErrorLog,
            chi2ReqAllowed,
            setChi2ReqAllowed,
            chi2QueRef
          );
        }
      }
      // }
    } catch (error) {
      recordedErrorLog("Vdf response handling error: ", error);
    }
  }

  // function handleVdfBinaryLoad(lastMessage) {
  //   try {
  //     console.log("last message: ", lastMessage);
  //   } catch (error) {
  //     recordedErrorLog(
  //       "There was a problem trying to handle binary vdf file load"
  //     );
  //   }
  // }

  useEffect(() => {
    try {
      if (lastJsonMessage) {
        if (hasProperty(lastJsonMessage, "requestID")) {
          const requestData = retrieveRequestData(lastJsonMessage.requestID);
          if (requestData !== null) {
            switch (requestData.type) {
              case "chi2-message":
                handleChi2Message(lastJsonMessage);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                setChi2ReqAllowed(true);
                break;
              case "vdf-request":
                handleVdfResponse(lastJsonMessage, requestData.requestedVdfs);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                break;
              case "binary-vdf-load":
                // handleVdfBinaryLoad(lastJsonMessage);
                handleVdfResponse(lastJsonMessage, requestData.requestedVdfs);
                deleteRequestDataEntry(lastJsonMessage.requestID);
                break;
              default:
                break;
            }
          }
        } else {
          throw new Error("Request ID was not attached to the json message.");
        }
      }
    } catch (error) {
      recordedErrorLog(
        "Last Json message useEffect handler has failed: ",
        error
      );
    }
  }, [lastJsonMessage]);

  const handleCloseIconClick = () => {
    setBroadcastOpen(false);
  };

  const handleReprocessClose = () => {
    setReprocessOpen(false);
    setFileToReprocess(null);
    setFileToChange(null);
  };

  const handleReprocessDone = (newFile) => {
    try {
      const updatedFiles = uploadedFiles.map((uFile) => {
        if (uFile.ID === newFile.ID) {
          return newFile;
        } else {
          return uFile;
        }
      });

      setUploadedFiles(updatedFiles);

      handleReprocessClose();
    } catch (error) {
      recordedErrorLog("Reprocess done handler failure: ", error);
    }
  };

  return (
    <Box sx={{ display: "flex" }} ref={dashboardRef}>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 3,
          height: "100vh",
          overflow: "scroll",
          width: 1,
        }}
      >
        <AppBar />
        <WindowSelector />
        <Container maxWidth={false} disableGutters className="mainContainer">
          <Grid
            container
            spacing={0.5}
            sx={{ mt: 0, marginTop: "0.5vh" }}
            className="gridContainer"
          >
            {/* dashboard is divided by 3 grids 
                First grid = data loading, data settings, models */}
            {/* {showLeftPanel ? <LeftSide /> : <></>} */}
            <LeftSide />
            {/* Second grid = graph */}
            <Grid item xs={5}>
              <GraphContainer />
            </Grid>
            {/* Third grid = fit results, params table, warnings and export (all imported components) */}
            {/* <RightSide setShowOtherPanel={setShowLeftPanel} /> */}
            <RightSide />
          </Grid>
          {/* imported Copyright component */}
          <div className="copyright">
            <Copyright sx={{ pb: 4 }} />
          </div>
        </Container>
      </Box>
      {selectedFiles !== undefined && selectedFiles.length == 1 ? (
        <StickyFileWindow
          selectedFiles={selectedFiles}
          updateSelected={updateSelected}
          callFromGraph={fromGraphState}
          resetSelection={resetSelection}
        />
      ) : (
        <></>
      )}
      <BroadcastModal
        elementRef={dashboardRef}
        isOpen={broadcastOpen}
        setIsOpen={setBroadcastOpen}
        handleClose={handleCloseIconClick}
      />
      <Modal
        isOpen={reprocessOpen}
        onRequestClose={handleReprocessClose}
        shouldCloseOnOverlayClick={true}
        contentLabel="File Re-Processing Modal"
        appElement={dashboardRef.current}
        id="fileReProcessModal"
        style={{
          content: {
            width: "80vw",
            height: "70vh",
            top: `10%`,
            left: "10%",
            right: "auto",
          },
          overlay: {
            zIndex: "8000",
          },
        }}
      >
        <SingleFileReprocess
          file={fileToReprocess}
          handleDone={handleReprocessDone}
          handleCancel={handleReprocessClose}
          modalId={"fileReProcessModal"}
        />
      </Modal>
    </Box>
  );
}
