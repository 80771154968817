import React from "react";
import {
  filterCurveDataToRange,
  getShortName,
  hasProperty,
  processSymbols,
  segmentsIntoSingleCurve,
  splitCurveIntoSegmentsByEdges,
} from "../../../utils/helpers";
import { processFileWithOptions } from "../../leftSide/Files/fileProcessing/processingLogic";
import { deepCopy, getModelsByIds } from "../../leftSide/Models/modelLogic";
import { createModelFitPayloadParams } from "../../middle/graphLogic";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HtmlTooltip from "../../commonComponents/HtmlTooltip";

export function createFitModelEntry(
  model,
  depth,
  indexArray,
  handleModelClick,
  selectedModelQuantitySet
) {
  if (model.outputs.length > 1) {
    return (
      <div
        key={indexArray + "|" + model.FE_ID}
        style={{ marginLeft: `${depth * 8}px` }}
      >
        <Accordion className="modelOutputAccordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1-content"
            id="panel1-header"
            className={`accordionSummary ${
              selectedModelQuantitySet &&
              selectedModelQuantitySet.modelId === model.FE_ID
                ? "selected"
                : ""
            }`}
          >
            {/* <div>
            {model.displayName}
            </div> */}
            <Typography>{model.displayName}</Typography>
          </AccordionSummary>
          <AccordionDetails className="accordionDetails">
            <div className="modelCurveAccordion">
              {model.outputs.map((output, index) => {
                return (
                  <div key={output.reffit_id}>
                    <HtmlTooltip
                      title={
                        <React.Fragment>
                          <div className="outputTooltip">
                            {processSymbols(output.description)}
                          </div>
                        </React.Fragment>
                      }
                    >
                      <div
                        className={`outputItem ${
                          index === model.outputs.length - 1 ? "lastItem" : ""
                        } ${
                          selectedModelQuantitySet &&
                          selectedModelQuantitySet.modelId === model.FE_ID &&
                          selectedModelQuantitySet.quantity === output.reffit_id
                            ? "selected"
                            : ""
                        }`}
                        onClick={() => {
                          handleModelClick(
                            model.FE_ID,
                            getShortName(model.displayName),
                            output.name,
                            output.reffit_id
                          );
                        }}
                      >
                        {processSymbols(output.name)}
                      </div>
                    </HtmlTooltip>
                  </div>
                );
              })}
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  } else if (model.outputs.length === 1) {
    const output = model.outputs[0];
    return (
      <div
        key={indexArray + "|" + model.FE_ID}
        style={{ marginLeft: `${depth * 8}px` }}
      >
        <div
          className={`singleModelOutput ${
            selectedModelQuantitySet &&
            selectedModelQuantitySet.modelId === model.FE_ID &&
            selectedModelQuantitySet.quantity === output.reffit_id
              ? "selected"
              : ""
          }`}
        >
          <HtmlTooltip
            title={
              <React.Fragment>
                <div
                  className="outputTooltip"
                  style={{ fontSize: "1.2em", textDecoration: "underline" }}
                >
                  {processSymbols(output.name)}
                </div>
                <div className="outputTooltip">
                  {processSymbols(output.description)}
                </div>
              </React.Fragment>
            }
          >
            <div
              className={`outputItem lastItem`}
              onClick={() => {
                handleModelClick(
                  model.FE_ID,
                  getShortName(model.displayName),
                  output.name,
                  output.reffit_id
                );
              }}
              id={`list-item-${model.displayName}`}
            >
              {/* {processSymbols(output.name)} */}
              {model.displayName}
            </div>
          </HtmlTooltip>
        </div>
      </div>
    );
  }
}

export function performChi2Message(
  chi2Terms,
  models,
  uploadedFiles,
  sendJsonMessage,
  user,
  recordedErrorLog,
  chi2ReqAllowed,
  setChi2ReqAllowed,
  chi2QueRef
) {
  try {
    if (chi2ReqAllowed) {
      let chi2TermsToUse = chi2Terms;
      if (chi2QueRef.current.length > 0) {
        for (let i = 0; i < chi2Terms.length; i++) {
          const term = chi2Terms[i];

          chi2QueRef.current = {
            ...chi2QueRef.current,
            [`${term.fileId}|${term.modelId}|${term.quantity}`]: term,
          };
        }
        chi2TermsToUse = Object.values(chi2QueRef.current);
      }
      const payload = generateChi2Payload(
        uploadedFiles,
        models,
        chi2TermsToUse,
        user,
        recordedErrorLog
      );

      chi2QueRef.current = {};

      sendJsonMessage(payload, { type: "chi2-message" });

      setChi2ReqAllowed(false);
    } else {
      for (let i = 0; i < chi2Terms.length; i++) {
        const term = chi2Terms[i];

        chi2QueRef.current = {
          ...chi2QueRef.current,
          [`${term.fileId}|${term.modelId}|${term.quantity}`]: term,
        };
      }
    }
  } catch (error) {
    recordedErrorLog("There was an error performing chi2 message", error);
  }
}

function generateChi2Payload(
  uploadedFiles,
  models,
  terms,
  user,
  recordedErrorLog
) {
  const termsModels = [...new Set(terms.map((term) => term.modelId))];
  const termsFiles = [...new Set(terms.map((term) => term.fileId))];

  const modelsForPayload = getModelsByIds(termsModels, deepCopy(models));

  let modelDataPayload = createModelFitPayloadParams(modelsForPayload);

  let fileDataPayload = [];

  for (let i = 0; i < termsFiles.length; i++) {
    const termFileId = termsFiles[i];
    const file = uploadedFiles.find((file) => file.ID == termFileId);
    let data = Object.prototype.hasOwnProperty.call(file, "dataPoints")
      ? file.dataPoints
      : processFileWithOptions(file.content, file.options, recordedErrorLog);
    // getDataPointsFromFile(file.content);

    data = filterCurveDataToRange(data, file.dataRangeMin, file.dataRangeMax);

    data = segmentsIntoSingleCurve(
      splitCurveIntoSegmentsByEdges(data, file.edges)
    );

    fileDataPayload.push({ dataid: termFileId, data: data });
  }

  let termsWithIDs = terms.map((term) => {
    return {
      modelid: term.modelId,
      dataid: term.fileId,
      weight: term.weight,
      quantity: term.quantity,
    };
  });

  let payload = {
    User: user.id,
    Model: {
      SendModel: modelDataPayload,
    },
    Data: {
      SendData: fileDataPayload,
    },
    Fit: {
      GetChi2: termsWithIDs,
    },
  };

  return payload;
}

export function getTotalChi2Terms(chi2Terms, selectedChi2Terms) {
  let totalChi2 = 0;
  let updated = false;

  for (let i = 0; i < chi2Terms.length; i++) {
    const term = chi2Terms[i];

    if (
      term.chi2 !== null &&
      term.weight !== 0 &&
      term.weight !== "" &&
      selectedChi2Terms.some((id) => id === term.id)
    ) {
      updated = true;
      totalChi2 += parseInt(term.chi2 * term.weight);
    }
  }

  if (updated) {
    return totalChi2;
  } else {
    return "N/A";
  }
}

export function getTotalActiveParams(chi2Terms, selectedChi2Terms) {
  let totalAP = 0;
  let updated = false;

  for (let i = 0; i < chi2Terms.length; i++) {
    const term = chi2Terms[i];

    if (
      term.chi2 !== null &&
      term.weight !== 0 &&
      term.weight !== "" &&
      selectedChi2Terms.some((id) => id === term.id)
    ) {
      updated = true;
      if (term.weight !== 0) {
        totalAP += parseInt(term.activeParams);
      }
    }
  }

  if (updated) {
    return totalAP;
  } else {
    return "N/A";
  }
}

export function updatedChi2(newTerms, currentTerms) {
  let updatedTerms = deepCopy(currentTerms);

  for (let i = 0; i < newTerms.length; i++) {
    const newTerm = newTerms[i];

    updatedTerms = updatedTerms.map((term) => {
      if (
        term.modelId === newTerm.ModelID &&
        term.fileId === newTerm.DataID &&
        term.quantity === newTerm.Dist
      ) {
        if (hasProperty(term, "needsUpdate")) {
          delete term.needsUpdate;
        }
        return {
          ...term,
          chi2: newTerm.value,
          activeParams: newTerm.nActiveParameters,
        };
      } else {
        return term;
      }
    });
  }

  return updatedTerms;
}

export function getListOfTermsToUpdate(terms, updatedModelIDs) {
  let termsToUpdate = [];

  for (let i = 0; i < terms.length; i++) {
    const term = terms[i];
    for (let j = 0; j < updatedModelIDs.length; j++) {
      const modelId = updatedModelIDs[j];
      if (term.modelId === modelId) {
        termsToUpdate.push(term);
      }
    }
  }

  return termsToUpdate;
}
