import React, { useContext, useState, useEffect } from "react";
import { DashboardContext } from "../../context/DashboardContext";
import "./windowSelector.scss";

function WindowSelector() {
  const {
    // demoOpen,
    // setDemoOpen,
    dataOpen,
    setDataOpen,
    modelsOpen,
    setModelsOpen,
    parametersOpen,
    setParametersOpen,
    fitOpen,
    setFitOpen,
    resultsOpen,
    setResultsOpen,
    modelData,
  } = useContext(DashboardContext);
  const [listForGauges, setListForGauges] = useState([]);

  useEffect(() => {
    const filteredList = modelData.filter((model) => model.reffitID === -113); //We filter only Raman
    setListForGauges(filteredList);
  }, [modelData]);

  return (
    <div className="windowSelector">
      <div className="switchPlatter" />
      <div className="switches">
        {/* <div
          className={`switch${demoOpen ? " open" : ""}`}
          onClick={() => setDemoOpen((old) => !old)}
          id="demo-switch"
        >
          Demo
        </div> */}
        <div
          className={`switch${dataOpen ? " open" : ""}`}
          onClick={() => setDataOpen((old) => !old)}
          id="data-switch"
        >
          Data
        </div>
        <div
          className={`switch${modelsOpen ? " open" : ""}`}
          onClick={() => setModelsOpen((old) => !old)}
          id="models-switch"
        >
          Experiments
        </div>
        <div
          className={`switch${parametersOpen ? " open" : ""}${
            modelData.length > 0 ? "" : " disabled"
          }`}
          onClick={() =>
            modelData.length > 0
              ? setParametersOpen((old) => !old)
              : setParametersOpen(false)
          }
          id="parameters-switch"
        >
          Parameters
        </div>
        <div
          className={`switch${fitOpen ? " open" : ""}`}
          onClick={() => setFitOpen((old) => !old)}
          id="fit-switch"
        >
          Fit
        </div>
        <div
          className={`switch${resultsOpen ? " open" : ""}${
            listForGauges.length > 0 ? "" : " disabled"
          }`}
          onClick={() =>
            listForGauges.length > 0
              ? setResultsOpen((old) => !old)
              : setResultsOpen(false)
          }
          id="fit-switch"
        >
          Results
        </div>
      </div>
    </div>
  );
}

export default WindowSelector;
